import React, { useState } from 'react';
import classNames from 'classnames';
import { IconBed, IconPen, OrangeButton, SmallButton } from 'components';
import { COLORS } from 'utils/colors';
import SingleBed from './singleBed';
import { updateBeds } from 'api/api';

export const Beds = ({ partner }) => {
  const [isInEditMode, setIsInEditMode] = useState(false);
  const { active_clients, bed_count, occupied_bed_count, id: partner_id } = partner;
  const [totalBeds, setTotalBeds] = useState(bed_count);
  const [bedStatuses, setBedStatuses] = useState(
    Array.from({ length: bed_count }, (_, index) => index + 1 <= occupied_bed_count)
  );
  const occupiedBeds = bedStatuses.filter(status => status).length;
  const availableBeds = totalBeds - occupiedBeds;

  const handleBedToggle = (index) => {
    const updatedStatuses = [...bedStatuses];
    updatedStatuses[index] = !updatedStatuses[index];
    setBedStatuses(updatedStatuses);
  };

  const handleSaveEdit = () => {
    if (isInEditMode) {
      const bedData = {
        total_beds_count: totalBeds,
        occupied_beds_count: occupiedBeds
      };

      updateBeds(bedData, partner_id, (data) => {
        setTotalBeds(data.bed_count);
        return setIsInEditMode(false);
      });
    }

    setIsInEditMode(true);
  }

  return (
    <div className='flex flex-col base-block pt-[30px] flex-1'>
      <div className='flex items-center justify-between px-[30px] mb-[17px]'>
        <p className='nunito-title'>Beds</p>
        <div className='flex items-stretch gap-x-4'>
          {isInEditMode && <SmallButton btnClassName='!h-[44px] !px-6' label='Cancel' onClick={() => setIsInEditMode(false)} />}
          <OrangeButton icon={!isInEditMode && <IconPen />} label={isInEditMode ? 'Save' : 'Edit'} onClick={handleSaveEdit} />
        </div>
      </div>
      <div className={classNames('relative w-full flex items-start pt-8 pb-[30px] gap-x-2.5 border-t border-color-lightBlue px-[30px]',
        'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-lightBlue before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2',
      )}>
        <div className='flex flex-col gap-y-6'>
          {isInEditMode ?
            <div className='flex flex-col gap-y-1'>
              <p className='text-14 font-medium'>Beds Count*</p>
              <div className='flex items-center gap-x-1'>
                <button onClick={() => setTotalBeds(+totalBeds - 1)} disabled={totalBeds <= occupiedBeds} className={`w-10 h-10 shadow-block-shadow text-center rounded-ten ${totalBeds <= 1 ? 'opacity-50' : ''}`}>-</button>
                <input
                  onChange={(e) => setTotalBeds(e.target.value)}
                  type='number'
                  className='w-10 h-10 border border-divider outline-divider outline-1 text-center rounded-ten text-14'
                  value={totalBeds}
                  readOnly
                />
                <button onClick={() => setTotalBeds(+totalBeds + 1)} className='w-10 h-10 shadow-block-shadow text-center rounded-ten'>+</button>
              </div>
            </div>
            :
            <div className='flex items-center gap-x-4'>
              <div className='bg-yellow rounded-full w-[84px] h-[84px] flex items-center justify-center'>
                <IconBed className='w-10 h-10 pointer-events-none' color={COLORS.orange} />
              </div>
              <div className='flex flex-col gap-y-1'>
                <p className='text-14 text-lightBlue'>Total</p>
                <p className='text-24'>{totalBeds}</p>
              </div>
            </div>}
          <div className='flex items-center gap-x-4'>
            <div className='bg-pink rounded-full w-[84px] h-[84px] flex items-center justify-center'>
              <IconBed className='w-10 h-10 pointer-events-none' color={COLORS.red} />
            </div>
            <div className='flex flex-col gap-y-1'>
              <p className='text-14 text-lightBlue'>Occupied</p>
              <p className='text-24'>{occupiedBeds}</p>
            </div>
          </div>
          <div className='flex items-center gap-x-4'>
            <div className='bg-lightGreen rounded-full w-[84px] h-[84px] flex items-center justify-center'>
              <IconBed className='w-10 h-10 pointer-events-none' color={COLORS.green} />
            </div>
            <div className='flex flex-col gap-y-1'>
              <p className='text-14 text-lightBlue'>Available</p>
              <p className='text-24'>{availableBeds}</p>
            </div>
          </div>
        </div>
        <div className='flex flex-wrap gap-2 max-h-[328px] scrollbar scrollbar-thumb-sky-700 scrollbar-track-sky-300  overflow-y-auto'>
          {Array.from({ length: totalBeds }).map((_, index) => {
            return (
              <SingleBed
                key={`bed-${index}`}
                isBedOccupied={bedStatuses[index]}
                toggleBed={() => handleBedToggle(index)}
                inEdit={isInEditMode}
                isPWClient={bedStatuses[index] && active_clients >= index + 1}
              />
            )
          })}
        </div>
      </div>
    </div>
  );
}

