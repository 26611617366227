import { DATE_FORMAT } from 'api/constants';
import { IconDownload } from 'components/icons/Download';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { CSVLink } from 'react-csv';


export const DownloadClientsCSV = ({ list, isManager }) => {
    const headers = useMemo(() => {
        const headers = [
            { label: "Client", key: "client" },
            { label: "Status", key: "status" },
            { label: "Week", key: "week" },
            { label: "SLH", key: "slh" },
            { label: "Start Date", key: "start_date" }
        ];

        return isManager ? [...headers, { label: "End Date", key: "end_date" }] : headers;
    }, [isManager])

    const handleDataManipulation = useMemo(() => {
        return list.map(item => ({
            client: item.client,
            status: item.status.replace('_', ' ').toUpperCase(),
            week: item.week,
            slh: item.partner,
            start_date: dayjs(item.startDate).format(DATE_FORMAT),
            end_date: isManager ? dayjs(item.terminationDate ? item.terminationDate : item.endDate).format(DATE_FORMAT) : null
        }))
    }, [list, isManager])

    return (
        <CSVLink
            headers={headers}
            data={handleDataManipulation}
            filename={"pf25-clients.csv"}
            target="_blank"
        >
            <IconDownload className='cursor-pointer' />
        </CSVLink>
    );
};

