import React, { useCallback, useEffect, useState } from 'react';
import { endpoints } from 'api/endpoints';
import { SLHCard, IconSearch, OrangeButton, IconPlus, DownloadPartnersCSV } from 'components';
import { Input } from 'antd';
import { COLORS } from 'utils/colors';
import { getRequestData } from 'api/api';
import { useNavigate } from 'react-router-dom';
import { ROLE_TYPES } from 'api/constants';

export const AdminPartners = () => {
  const navigate = useNavigate();
  const [partnersList, setPartnersList] = useState();
  const [searchWord, setSearchWord] = useState(null);

  const getPartners = useCallback(async (filters) => {
    const { data } = await getRequestData(endpoints.getPartners(filters), ROLE_TYPES.admin);
    if (data) setPartnersList(data);
  },[]);

  useEffect(() => {
    getPartners({})
  }, [getPartners])

  const storePartners = useCallback(() => {
    const totalItems = partnersList.results.length;
    const itemsPerRow = 3;
    const rows = Math.ceil(totalItems / itemsPerRow);
    const startIdxLastRow = (rows - 1) * itemsPerRow;

    return partnersList.results?.map((partner, index) => {
      const isLastRowItem = index >= startIdxLastRow;

      return <SLHCard {...partner} parentClassName={isLastRowItem} key={`${partner.name}-${index}`} />;
    })
  }, [partnersList]);

  const onSearch = (e) => {
    const searchTerm = e.target.value;

    if (searchTerm.length > 2 || (searchWord && (searchWord.length > searchTerm.length))) {
      getPartners({ search: searchTerm });
    }
    setSearchWord(searchTerm);

  }

  return (
    <div className='flex flex-col w-full'>
      <div className='flex justify-between w-full'>
        <p className='nunito-title uppercase'>Partners (SLH)</p>
        <div className='flex items-center gap-x-6'>
          <Input
            className='search w-[300px] text-lightBlue text-16 rounded-ten bg-light'
            type='text'
            placeholder="Search"
            addonBefore={<IconSearch color={COLORS.lightBlue} />}
            onChange={onSearch}
          />
          <OrangeButton onClick={() => navigate('/admin/create-partner')} label='Add new partner' icon={<IconPlus />} />

          {partnersList && <DownloadPartnersCSV list={partnersList.results} />}
        </div>
      </div>
      {partnersList &&
        <div className="flex flex-wrap items-stretch w-full gap-4 mt-6">
          {storePartners()}
        </div>}
    </div>
  );
}

