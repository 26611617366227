import { IconDownload } from 'components/icons/Download';
import React, { useMemo } from 'react';
import { CSVLink } from 'react-csv';

export const DownloadPartnersCSV = ({ list }) => {
    const headers = useMemo(() => {
        return [
            { label: "SLH Name", key: "slh_name" },
            { label: "SLH Address", key: "slh_address" },
            { label: "SLH Rank", key: "slh_rank" },
            { label: "Beds (free/total)", key: "beds" },
            { label: "Weekly Rate", key: "weekly_rate" },
            { label: "Website", key: "website" },
            { label: "Manager Name", key: "manager_name" },
            { label: "Manager Phone", key: "manager_phone" },
            { label: "Manager Email", key: "manager_email" }
        ];

    }, [])

    const handleDataManipulation = useMemo(() => {
        return list.map(item => ({
            slh_name: item.name,
            slh_address: item.address,
            slh_rank: item.rank,
            beds: `${item.occupied_bed_count ?? 0}/${item.bed_count ?? 0}`,
            weekly_rate: item.weekly_rate,
            website: item.website,
            manager_name: `${item.manager.firstname ? item.manager.firstname : ''} ${item.manager.lastname ? item.manager.lastname : ''}`,
            manager_phone: item.manager.phone,
            manager_email: item.manager.email
        }))
    }, [list])

    return (
        <CSVLink
            headers={headers}
            data={handleDataManipulation}
            filename={"pf25-partners.csv"}
            target="_blank"
        >
            <IconDownload className='cursor-pointer' />
        </CSVLink>
    );
};

