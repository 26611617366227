import React, { useRef, useState } from 'react';
import { FormInput, IconSmallArrow, OrangeButton, SmallButton } from 'components';
import { Form, Input } from 'antd';
import Logo from 'assets/images/logo.svg';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { forgotPassword, resetPassword, verifyCode } from 'api/api';

export const ForgotPassword = ({ showLogin, domain }) => {
  const [userEmail, setUserEmail] = useState('');
  const [verifiedCode, setVerifiedCode] = useState(null);

  const handleEmailSubmission = async (email) => {
    const res = await forgotPassword(email, domain);

    if (res?.status === 201) {
      setUserEmail(email)
    }
  };

  const handleVerify = async (code) => {
    const formData = { email: userEmail, code };
    const res = await verifyCode(formData, domain);

    if (res?.status === 200) {
      setVerifiedCode(res.data.code);
    }
  };

  const handleResetPassword = async (password) => {
    const formData = { email: userEmail, passwd: password, code: verifiedCode };
    const res = await resetPassword(formData, domain);

    if (res.status === 201) {
      toast.success('Password changed successfully');
      setUserEmail('');
      setVerifiedCode(null);
      showLogin();
    }
  }

  const storeModalBody = () => {
    if (userEmail) {
      return verifiedCode ? (
        <>
          <p className='text-lightBlue text-base leading-[24px] max-w-[350px] text-center'>
            Set the new password for Your account
          </p>
          <ResetPassword reset={handleResetPassword} />
        </>
      ) : (
        <>
          <p className='text-lightBlue text-base leading-[24px] max-w-[350px] text-center'>
            Enter the 4 digit code that you received on Your email
          </p>
          <VerificationCodeForm verify={handleVerify} email={userEmail} callResend={handleEmailSubmission} />
        </>
      );
    }

    return (
      <>
        <p className='text-lightBlue text-base leading-[24px] max-w-[350px] text-center'>
          Enter Your email for the verification process, we will send 4 digits code to Your email.
        </p>
        <SubmitEmail submitEmail={handleEmailSubmission} />
      </>
    );
  };


  return (
    <div className='flex flex-col bg-white rounded-[60px] pt-10 pb-12 w-[40rem] h-[calc(100%-64px)] min-h-[46rem] my-8 relative'>
      <div onClick={showLogin} className='absolute left-[30px] top-16 w-10 h-10 bg-white rounded-lg shadow-block-shadow flex items-center justify-center cursor-pointer'>
        <IconSmallArrow className='w-2 h-4' />
      </div>
      <img className='w-48 mb-6 self-center' src={Logo} alt='pw25 logo' />
      <div className={classNames('flex-1 relative w-full flex flex-col py-8 border-b border-t border-color-divider px-20 self-center items-center',
        'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-divider before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2',
        'after:content-[""] after:absolute after:w-4 after:h-4 after:rounded-full after:border after:border-color-divider after:bg-white after:z-10 after:bottom-0 after:left-1/2 after:-translate-x-1/2 after:translate-y-1/2',
      )}>
        <p className='mb-4 nunito-title'>{verifiedCode ? 'Reset' : 'Forgot'} Password</p>
        {storeModalBody()}
      </div>
      <SmallButton className='self-center !mt-7' label='Login' onClick={showLogin} />
    </div>
  )
}

const SubmitEmail = ({ submitEmail }) => {
  const [btnDisabled, setBtnDisabled] = useState(true);

  const onValuesChange = (_, allValues) => {
    const email = allValues.email;
    setBtnDisabled(!(email !== undefined && email !== ''));
  };

  const handleEmailSubmit = async (values) => {
    const email = values.email;
    await submitEmail(email);
  }

  return (
    <Form
      className='mt-16 flex flex-col w-full gap-y-6'
      name="forgot-password"
      onFinish={handleEmailSubmit}
      onValuesChange={onValuesChange}
      autoComplete="off"
      requiredMark={'optional'}
      clearOnDestroy={false}
    >
      <FormInput
        label="Email"
        name="email"
        placeholder="Email"
        required
        errorMessage='Please enter valid email'
      />
      <OrangeButton type='submit' className='self-center mt-10' label='Send' disabled={btnDisabled} />
    </Form>
  )
}

const VerificationCodeForm = ({ verify, email, callResend }) => {
  const [code, setCode] = useState(['', '', '', '']);
  const inputsRef = useRef([]);

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (value.length <= 1) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (value && index < inputsRef.current.length - 1) {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !e.target.value && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  const isCodeComplete = code.every((digit) => digit !== '');

  const handleVerifyCode = () => {
    const verificationCode = code.join('');

    verify(verificationCode);
  };


  const handleResend = async () => {
    await callResend(email);
  };

  return (
    <div className='flex flex-col mt-16'>
      <p className='text-14 font-medium mb-1'>Code</p>
      <Form className='flex flex-col' onFinish={handleVerifyCode}>
        <div className='flex gap-x-6'>
          {[0, 1, 2, 3].map((_, index) => (
            <Form.Item key={index}>
              <Input
                className='verification-input'
                type="number"
                maxLength={1}
                ref={(el) => (inputsRef.current[index] = el)}
                onChange={(e) => handleInputChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            </Form.Item>
          ))}
        </div>
        <button type='button' className='text-14 font-medium text-blue mt-7' onClick={handleResend}>Resend</button>
        <OrangeButton type='submit' className='self-center mt-10' label='Verify' disabled={!isCodeComplete} />
      </Form>
    </div>
  );
};

const ResetPassword = ({ reset }) => {
  const [btnDisabled, setBtnDisabled] = useState(true);

  const onValuesChange = (_, allValues) => {
    const { password, confirm_password } = allValues;
    const isPassesMatching = password === confirm_password;
    const isDisabled = (password !== undefined && password !== '' && confirm_password !== undefined && confirm_password !== '');
    setBtnDisabled(!(isDisabled && isPassesMatching));
  };

  const handleReset = async (allValues) => {
    const { password } = allValues;

    await reset(password);
  }

  return (
    <Form
      onFinish={handleReset}
      requiredMark={'optional'}
      onValuesChange={onValuesChange}
      className='flex flex-col w-full mt-16'>
      <div className='flex flex-col gap-y-6'>
        <FormInput
          type='password'
          label="New Password"
          name="password"
          placeholder="Password"
          required
        />
        <FormInput
          type='password'
          label="Confirm Password"
          name="confirm_password"
          placeholder="Password"
          required
        />
      </div>
      <OrangeButton type='submit' className='self-center mt-16' label='Save' disabled={btnDisabled} />
    </Form>
  )
}