import React from 'react';
import classNames from 'classnames';
import { formatPriceWithComma } from 'utils/helpers';
import { useUser } from 'context/userContext';

export const AssociationForSoberLiving = ({ partner }) => {
  const { userDetails } = useUser();

  return (
    <div className='flex flex-col gap-[18px] base-block pt-[30px] w-[450px]'>
      <p className='nunito-title px-[30px] pb-[11px]'>SLH Information</p>
      <div className={classNames('relative w-full flex flex-col pt-6 pb-[30px] gap-y-[18px] border-t border-color-lightBlue px-[30px] self-center items-center',
        'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-lightBlue before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2',
      )}>
        <div className='flex flex-col gap-y-3 w-full'>
          <div className='flex  justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Classification</p>
            <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>{partner.rank}</p>
          </div>
          <div className='flex  justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Weekly Rate</p>
            <span className='flex-1  border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>${formatPriceWithComma(partner.weekly_rate)}</p>
          </div>
          <div className='flex justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Parent</p>
            <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>{userDetails?.partner_name}</p>
          </div>
          <div className='flex justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Gender</p>
            <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>{partner.gender}</p>
          </div>
          <div className='flex justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Region</p>
            <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>{partner.region}</p>
          </div>
          <div className='flex justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Address</p>
            <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>{partner.address}</p>
          </div>
          <div className='flex justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Website</p>
            <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>{partner.website}</p>
          </div>
          <div className='flex justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Manager</p>
            <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>{partner.manager_name}</p>
          </div>
          <div className='flex justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Manager Phone</p>
            <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>{partner.manager_phone}</p>
          </div>
          <div className='flex justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Manager Email</p>
            <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>{userDetails?.email}</p>
          </div>
          <div className='flex justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Supervisor</p>
            <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>{partner.supervisor_name}</p>
          </div>
          <div className='flex justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Supervisor Phone</p>
            <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>{partner.supervisor_phone}</p>
          </div>
          <div className='flex justify-between'>
            <p className='text-14 text-lightBlue whitespace-nowrap'>Supervisor Email</p>
            <span className='flex-1 border-b border-dashed border-color-divider min-w-3' />
            <p className='text-14 line-clamp-1 max-w-[unset]'>{partner.supervisor_email}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

