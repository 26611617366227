import React, { useCallback, useEffect, useState } from 'react';
import { Form, DatePicker, Checkbox, Radio } from 'antd';
import { CustomUploadPhoto, IconSoberLiving, FormInput, IconSmallArrow, OrangeButton, SelectOption, IconInfo, FileUpload, IconCalendar, IconSearch, IconClipboardType, TooltipButton } from 'components';
import classNames from 'classnames';
import { COLORS } from 'utils/colors';
import dayjs from 'dayjs';
import { createClient, getRequestData, updateUserImage, uploadDocument } from 'api/api';
import { endpoints } from 'api/endpoints';
import { useNavigate } from 'react-router-dom';
import { DATE_FORMAT, ROLE_TYPES } from 'api/constants';

export const CreateClientByAdmin = () => {
  const navigate = useNavigate();
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [partnersList, setPartnersList] = useState([]);
  const [formTypes, setFormTypes] = useState([]);
  const [avatarImg, setAvatarImg] = useState(null);
  const [formData, setFormData] = useState({
    client: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      confirm_password: "",
      role: "client",
      gender: "M",
      phone: "",
      pronoun: "",
      birthdate: ""
    },
    partner: {
      id: null,
      approved: false,
      startDate: ""
    },
    tasks: [1],
  });

  useEffect(() => {
    (async () => {
      const { data } = await getRequestData(endpoints.getPartners({}), ROLE_TYPES.admin);

      if (data) {
        const partners = data.results.map((i) => {
          return { label: i.name, value: i.id }
        })
        setPartnersList(partners);
      }
    })();

    (async () => {
      const { data } = await getRequestData(endpoints.getForms, ROLE_TYPES.admin);

      if (data) {
        const forms = data.map(({ feedback, ...rest }) => rest);
        setFormTypes(forms);
      }
    })();
  }, [])

  useEffect(() => {
    const doesPasswordsMatch = formData.client.password === formData.client.confirm_password;
    const isFormValid = !!formData.partner.id && !!formData.partner.approved && !!formData.partner.startDate && !!formData.client.birthdate && !!formData.client.email && !!formData.client.password && !!formData.client.firstname && !!formData.client.lastname && doesPasswordsMatch;
    setBtnDisabled(!isFormValid);
  }, [formData.client.birthdate, formData.client.confirm_password, formData.client.email, formData.client.firstname, formData.client.lastname, formData.client.password, formData.partner.approved, formData.partner.id, formData.partner.startDate])
  

  const onValuesChange = (_, allValues) => {
    const { firstname, lastname, email, pronoun, password, confirm_password, phone } = allValues;

    setFormData({ ...formData, client: { ...formData.client, firstname, lastname, email, pronoun, password, confirm_password, phone } });
  };

  const handleImageUpload = (file) => {
    setAvatarImg(file);
  };

  const handleDocSelect = (file) => {
    setSelectedDoc(file);
  };

  const handleGenderChange = (e) => {
    const shortGender = e.target.value.slice(0, 1).toUpperCase();

    setFormData({
      ...formData,
      client: {
        ...formData.client,
        gender: shortGender,
      }
    });
  }

  const handleAcceptedAtSLHChange = (e) => {
    setFormData({
      ...formData,
      partner: {
        ...formData.partner,
        approved: e.target.checked,
      }
    });

  }

  const handleDateOfBornChange = (date) => {
    const birthdate = dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ')
    setFormData({
      ...formData,
      client: {
        ...formData.client,
        birthdate
      }
    });
  }
  const handleStartDateChange = (date) => {
    const startDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ')
    setFormData({
      ...formData,
      partner: {
        ...formData.partner,
        startDate
      }
    });
  }
  const handleSLHSelectChange = (value) => {
    setFormData({
      ...formData,
      partner: {
        ...formData.partner,
        id: value,
      }
    });
  }

  const handleTaskChange = (e, task_id) => {
    const { tasks } = formData;
    const isChecked = e.target.checked;

    const updatedTasks = isChecked
      ? [...tasks, task_id]
      : tasks.filter(id => id !== task_id);

    setFormData(prevFormData => ({
      ...prevFormData,
      tasks: updatedTasks
    }));
  }

  const handleFormSubmit = useCallback(() => {
    createClient(formData, ROLE_TYPES.admin, (data) => {
      const userID = data.user.id;
      selectedDoc && uploadDocument(userID, selectedDoc, ROLE_TYPES.admin);
      avatarImg && updateUserImage(userID, avatarImg, ROLE_TYPES.admin);
      navigate('/admin/clients');
    });
  }, [avatarImg, formData, navigate, selectedDoc])

  return (
    <Form
      name='create-client-by-admin'
      requiredMark={'optional'}
      className='flex flex-col w-full gap-6'
      onValuesChange={onValuesChange}
    >
      <div className='flex justify-between w-full'>
        <div className='flex items-center gap-x-4'>
          <div onClick={() => navigate('/admin/clients')} className='w-10 h-10 bg-white rounded-lg shadow-block-shadow flex items-center justify-center cursor-pointer'>
            <IconSmallArrow className='w-2 h-4' />
          </div>
          <p className='nunito-title'>Add new Client</p>
        </div>
        <OrangeButton onClick={handleFormSubmit} label='Save' disabled={btnDisabled} />
      </div>
      <div className='flex gap-6'>
        <div className='flex flex-col gap-[18px] base-block pt-[30px] w-[442px]'>
          <p className='nunito-title px-[30px] pb-[11px]'>Client Information</p>
          <div className={classNames('relative w-full flex flex-col pt-14 pb-[30px] gap-y-[18px] border-t border-color-lightBlue px-[30px] self-center items-center',
            'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-lightBlue before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2',
          )}>
            <CustomUploadPhoto className='self-center' onImageUpload={handleImageUpload} />
            <div className='flex gap-x-[18px]'>
              <FormInput
                label="First Name*"
                name="firstname"
                placeholder="First Name"
                required
              />
              <FormInput
                label="Last Name*"
                name="lastname"
                placeholder="Last Name"
                required
              />
            </div>
            <div className='flex flex-col gap-y-1 w-full'>
              <p className='text-14 font-medium'>Date Of Birth*</p>
              <DatePicker
                onChange={(_, date) => handleDateOfBornChange(date)}
                format={DATE_FORMAT}
                placeholder='Pick Date'
                className='h-[44px] text-base font-medium base-block rounded-lg '
                name='date-of-born'
                suffixIcon={<IconCalendar color={COLORS.blue} />}
              />
            </div>
            <FormInput
              className='w-full'
              type={'email'}
              label="Email*"
              name="email"
              placeholder="Email"
              required
            />
            <div className='flex gap-x-[18px]'>
              <FormInput
                type='password'
                label="Password*"
                name="password"
                placeholder="Password"
                required
              />
              <FormInput
                type='password'
                label="Confirm Password*"
                name="confirm_password"
                placeholder="Password"
                required
              />
            </div>
            <FormInput
              className='w-full'
              type='number'
              label="Phone"
              name="phone"
              placeholder="Phone"
              prefix='+1'
            />
            <div className='flex flex-col gap-y-1 w-full'>
              <p className='text-14 font-medium'>Document</p>
              <FileUpload className='w-full' onFileSelect={handleDocSelect} maxCount={1} />
            </div>
            <div className='flex flex-col w-full gap-y-1'>
              <p className='text-14 font-medium'>SLH*</p>
              <SelectOption
                onSelect={(value) => handleSLHSelectChange(value)}
                dropdownStyle={{ padding: '16px', background: 'white' }}
                required
                suffixIcon={<IconSmallArrow color={COLORS.black} className='rotate-[-90deg] w-3 h-3' />}
                className='h-[44px] text-base font-medium base-block rounded-lg'
                placeholder='Choose Home'
                dropdownRender={(originalNode) => originalNode}
                optionRender={(option) => (
                  <div className='flex items-center px-2 py-1 gap-x-1 text-black rounded-md capitalize duration-150 hover:bg-orange'>
                    <IconSoberLiving />
                    <p className='font-14'>{option.label}</p>
                  </div>
                )}
                options={partnersList}
              />
              <div className='flex justify-between mt-1'>
                <Checkbox onChange={handleAcceptedAtSLHChange} name='accepted-slh' className='flex items-center'><p className='text-16 ml-2.5'>Accepted at SLH*</p></Checkbox>
                <TooltipButton
                  icon={<IconInfo color={COLORS.blue} />}
                  text='Info'
                  tooltipText='Clients must have already applied for entry to a partner SLH and been accepted for a term of residency at the SLH.' />
              </div>
            </div>
            <div className='flex flex-col gap-y-1 w-full'>
              <p className='text-14 font-medium'>Start Date*</p>
              <DatePicker
                onChange={(_, date) => handleStartDateChange(date)}
                format='MM.DD.YYYY'
                placeholder='Pick Date'
                className='h-[44px] text-base font-medium base-block rounded-lg'
                name='date-of-start'
                suffixIcon={<IconCalendar color={COLORS.blue} />}
              />
            </div>
            <div className='flex flex-col w-full gap-y-1'>
              <p>Gender*</p>
              <Radio.Group onChange={(e) => handleGenderChange(e)} className='gap-y-4' name="gender" defaultValue={'Male'}>
                <Radio className='px-4 py-2.5 bg-white border border-color-divider rounded-xl ' value={'Male'}>Male</Radio>
                <Radio className='px-4 py-2.5 bg-white border border-color-divider rounded-xl ' value={'Female'}>Female</Radio>
              </Radio.Group>
            </div>
            <FormInput
              className='w-full'
              label="Identify as"
              name="pronoun"
              placeholder="Identify as"
            />
          </div>
        </div>
        <div className='flex flex-col base-block pt-[30px] flex-1'>
          <div className='flex justify-between px-[30px] pb-[17px]'>
            <p className='nunito-title'>FORMS</p>
            {/* <Input
              className='search w-[300px] text-lightBlue text-16 rounded-ten bg-light'
              type='text'
              placeholder="Search"
              addonBefore={<IconSearch color={COLORS.lightBlue} />}
              onSearch={onSearch}
            /> */}
          </div>
          {formTypes.length > 0 && <div className={classNames('relative w-full flex flex-col pt-6 pb-[30px] border-t border-color-lightBlue px-[30px] self-center items-center',
            'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-lightBlue before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2',
          )}>
            <div className='flex justify-between items-center bg-white shadow-block-shadow rounded-lg w-full p-4 gap-x-4'>
              <Checkbox name='weekly-form' className='weekly-forms !bg-transparent' checked>
                <div className='flex items-center'>
                  <IconClipboardType className='ml-2.5 mr-1 min-w-6 min-h-6' />
                  <p className='text-16'>{formTypes[0]?.name}*</p>
                </div>
              </Checkbox>
              <TooltipButton
                icon={<IconInfo color={COLORS.blue} />}
                text='Info'
                tooltipText='This is weekly summary form that client will get at the completion of the week during his stay in SLH.' />
            </div>
            <div className='flex flex-col mt-6 pt-6 border-t border-color-divider w-full'>
              <p className='text-14 mb-1'>Additional Form</p>
              {formTypes.map(({ name, form_id }) => {
                return (
                  <div className='flex justify-between items-center bg-white shadow-block-shadow rounded-lg w-full p-4 gap-x-4 mb-6' key={name}>
                    <Checkbox name='weekly-form' className='weekly-forms !bg-transparent' onChange={(e) => handleTaskChange(e, 2)}>
                      <div className='flex items-center'>
                        <IconClipboardType className='ml-2.5 mr-1 min-w-6 min-h-6' />
                        <p className='text-16'>Impact of Dogs on Well-Being in Sober Homes</p>
                      </div>
                    </Checkbox>
                    <TooltipButton
                      icon={<IconInfo color={COLORS.blue} />}
                      text='Info'
                      tooltipText='The client will get changed Weekly Summary Form that will cover also DOER related questions.' />
                  </div>
                )
              })}
            </div>
          </div>}
        </div>
      </div>
    </Form>
  );
};

